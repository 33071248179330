import { createRouter, createWebHistory } from 'vue-router'

import routes from './routes'
import useAuthUser from '../composables/UseAuthUser'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from) => {
  const { isLoggedIn } = useAuthUser()

  const _isLoggedIn = await isLoggedIn()

  if (to.hash.includes('type=recovery') && to.name !== 'reset-password') {
    const accessToken = to.hash.split('&')[0]
    const token = accessToken.replace('#access_token=', '')

    return { name: 'reset-password', query: { token } }
  }

  if (
    !_isLoggedIn &&
    to.meta.requiresAuth &&
    !Object.keys(to.query).includes('fromEmail')
  ) {
    return { name: 'login' }
  }
})

export default router
