/* eslint-disable no-console */

import { register } from 'register-service-worker'
import useNotify from '@/composables/UseNotify'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      useNotify.notifyInfo(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      useNotify.notifyInfo('Service worker has been registered.')
    },
    cached () {
      useNotify.notifyInfo('Content has been cached for offline use.')
    },
    updatefound () {
      useNotify.notifyInfo('New content is downloading.')
    },
    updated () {
      useNotify.notifyInfo('New content is available; please refresh.')
    },
    offline () {
      useNotify.notifyWarning('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      useNotify.notifyError(`Error during service worker registration: ${error}`)
    }
  })
}
