const routes = [
  {
    path: '/',
    component: () => import('@/layouts/MainLayout'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/Home')
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('@/views/About')
      },
      {
        path: 'politica-privacidade',
        name: 'politica-privacidade',
        component: () => import('@/views/PoliticaPrivacidade')
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/CleanLayout'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('@/views/Register')
      },
      {
        path: 'email-confirmation',
        name: 'email-confirmation',
        component: () => import('@/views/EmailConfirmation')
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/ForgotPassword')
      },
      {
        path: 'reset-password',
        name: 'reset-password',
        component: () => import('@/views/ResetPassword')
      }
    ]
  },
  { /** PRIVATE ROUTES */
    path: '/',
    component: () => import('@/layouts/MainLayout'),
    children: [
      {
        path: 'me',
        name: 'me',
        component: () => import('@/views/Me')
      },
      {
        path: 'category',
        name: 'category',
        component: () => import('@/views/categoria/List')
      },
      {
        path: 'form-category/:id?',
        name: 'form-category',
        component: () => import('@/views/categoria/Form')
      },
      {
        path: 'theme',
        name: 'theme',
        component: () => import('@/views/tema/List')
      },
      {
        path: 'form-theme/:id?',
        name: 'form-theme',
        component: () => import('@/views/tema/Form')
      },
      {
        path: 'phrase',
        name: 'phrase',
        component: () => import('@/views/frase/List')
      },
      {
        path: 'form-phrase/:id?',
        name: 'form-phrase',
        component: () => import('@/views/frase/Form')
      },
      {
        path: 'author',
        name: 'author',
        component: () => import('@/views/autor/List')
      },
      {
        path: 'form-author/:id?',
        name: 'form-author',
        component: () => import('@/views/autor/Form')
      }
    ],
    meta: { requiresAuth: true }
  }
]

export default routes
