import useQuasar from 'quasar/src/composables/use-quasar.js';

export default function useNotify () {
  const $q = useQuasar()

  $q.notify.setDefaults({
    timeout: 2500,
    textColor: 'white',
    actions: [{ icon: 'close', color: 'white' }]
  })

  const notifySuccess = (message) => {
    $q.notify({
      type: 'positive',
      message
    })
  }
  const notifyError = (message) => {
    $q.notify({
      type: 'negative',
      message
    })
  }

  const notifyInfo = (message) => {
    $q.notify({
      type: 'info',
      message
    })
  }

  const notifyWarning = (message) => {
    $q.notify({
      type: 'warning',
      message
    })
  }

  return {
    notifySuccess,
    notifyError,
    notifyInfo,
    notifyWarning
  }
}
